// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { List } from 'immutable';
import moment from '../../../lib/moment';
import {
    parseProjectImport,
    acknowledgeProjectImport,
    clearProjectDiff,
    editProject,
    upsertProject, pushCrumbPath,
} from '../../../actions';
import ProjectDiffRow from '../../Project/ProjectOverview/ProjectDiffRow';
import AppModule from '../../AppModule';
import classnames from 'classnames';


import ProjectImportDialog from '../../ProjectImportDialog';

import { withRouter } from 'react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { fetchInspectionAssigns } from '../../../actions/inspectionActions';
import ProjectsListOverview from './ProjectsListOverview';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ProjectOverview.module.scss';
import i18n from "../../../i18n";

type Props = {
    loading: boolean,
    projects: Array<any>,
    parseImport: (file: any) => void,
    acknowledgeImport: (file: any) => void,
    clearDiff: () => void,
    editProject: (project?: TProject) => void,
    editproject: TProject,
    onSave: (project?: TProject) => void,
    startEditProject: (project: TProject) => void,
    inProgress: boolean,
    diff: { removed: List<TProject>, added: List<TProject> },
    location: mixed,
    goTo: (path) => mixed,
};

type State = {
    page: 0,
    rowsPerPage: 10,
    showUpload: boolean,
    showDialogChoose: boolean,
    searchtext: string,
};


const mapStateToProps = (store) => {
    let inspectionAssigns = store.entities.inspectionAssigns;

    if (inspectionAssigns) {
        inspectionAssigns = inspectionAssigns.allIds.map(
            (id) => inspectionAssigns.byId[id]
        );
    }

    return {
        diff: store.drafts.projects.diff,
        inProgress: store.drafts.projects.inProgress,
        editproject: store.drafts.projects.editproject,
        inspectionAssigns: inspectionAssigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        parseImport: bindActionCreators(parseProjectImport, dispatch),
        acknowledgeImport: bindActionCreators(
            acknowledgeProjectImport,
            dispatch
        ),
        editProject: bindActionCreators(editProject, dispatch),
        editToolbox: (id) => {
            dispatch(push('/Project/' + id));
        },
        newProject: () => {
            dispatch(push('/project/aanmaken'));
        },
        onSave: (project) => {
            upsertProject(project)(dispatch);
        },
        clearDiff: () => {
            dispatch(clearProjectDiff());
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },

    };
};

class ProjectOverview extends React.Component<Props, State> {
    myList = [];
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        startPage: 0,
        startRowsPerPage: 10,
        actions: [
            {
                id: 'edit',
                label: 'Edit',
                isVisible: (id) => this.isVisible(id),
            },
        ],
        columns: [
            {
                id: 'omschrijving',
                numeric: false,
                size: '30%',
                label: 'Project / werkgebied',
            },
            { id: 'actions', numeric: true, size: '5%', label: '' },
        ],
        showUpload: false,
        showDialogChoose: false,
        searchtext: '',
        inspectionsAssignsByProject: [],
    };

    setPage = (page) => {
        console.log("INFO: ProjectOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ProjectsListOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    componentDidMount() {
        this.props.fetchInspectionAssigns();

        // If url params rows and page go to right page
        if (this.props.location && this.props.location.search && this.props.location.search.includes("page")) {
            const jumpToPage = new URLSearchParams(this.props.location.search).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({ startPage: Number(jumpToPage)});
        }
        if (this.props.location && this.props.location.search && this.props.location.search.includes("rowsPerPage")) {
            const startRowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({ startRowsPerPage: Number(startRowsPerPage)});

        }

        if (this.props.location.hash === '#new') {
            this.openChooseDialog();
        }

        this.setState({
            columns: [
                {
                    id: 'omschrijving',
                    numeric: false,
                    size: '30%',
                    label: 'Project/work area',
                },
                { id: 'actions', numeric: true, size: '5%', label: '' },
            ],
        });

    }

    createInspectionByProjectList(activeProjects) {
        let inspectionsAssignsByProject = [];

        activeProjects.forEach((project) => {
            if (!inspectionsAssignsByProject[project.id]) {
                inspectionsAssignsByProject[project.id] = [];
            }
        });

        inspectionsAssignsByProject['999InvullenTijdenInspectie'] = [];

        this.props.inspectionAssigns.forEach((ia) => {
            if (ia.projectId) {
                if (inspectionsAssignsByProject[ia.projectId]) {
                    inspectionsAssignsByProject[ia.projectId].push(ia);
                } else {
                    inspectionsAssignsByProject[ia.projectId] = [ia];
                }
            }
        });

        return inspectionsAssignsByProject;
    }

    getLastSentDate(inspectionAssignsList) {
        let date;
        if (inspectionAssignsList && inspectionAssignsList[0]) {
            date = inspectionAssignsList[0].deployed;
            inspectionAssignsList.forEach((ia) => {
                if (moment(date).isBefore(moment(ia.deployed))) {
                    date = ia.deployed;
                }
            });
        }
        return date;
    }

    countFinishedInspectionsPerProject(inspectionAssignsList) {
        let finished = 0;
        inspectionAssignsList.forEach((ia) => {
            if (ia.result && ia.result.complete) {
                finished++;
            }
        });

        return finished;
    }

    isVisible = (rowId) => {
        const proj = this.props.projects.find((proj) => proj.id === rowId);

        if (proj) {
            return proj.importType !== 'IMPORT';
        }
        return true;
    };

    handleAction = (event, action) => {
        if (action.id === 'edit') {
            this.props.startEditProject(action.rowId);
        }
    };

    mapProjectToRow = (project) => ({
        ...project,
        omschrijving: project.omschrijving,
        totalWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length
            : 0,
        lastSent: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? moment(
                      this.getLastSentDate(
                          this.state.inspectionsAssignsByProject[project.id]
                      )
                  ).format('YYYY-MM-DD HH:mm')
                : '-'
            : '-',
        finishedWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? this.countFinishedInspectionsPerProject(
                      this.state.inspectionsAssignsByProject[project.id]
                  )
                : '0'
            : '0',
        openWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? this.state.inspectionsAssignsByProject[project.id].length -
                  this.countFinishedInspectionsPerProject(
                      this.state.inspectionsAssignsByProject[project.id]
                  )
                : '0'
            : '0',
    });

    ProjectStateToText = (state) => {
        const { t } = this.props;
        switch (state) {
            case 'ADDED':
                return t('Added');
            case 'ACTIVE':
                return t('Active');
            case 'INACTIVE':
                return t('Inactive');
            default:
                return state;
        }
    };

    saveAndClose = (project ) => {
        this.props.onSave(project);
        this.closeChooseDialog();
    }

    openUploadDialog = () => {
        this.setState({
            showUpload: true,
        });
        this.closeChooseDialog();
    };

    openChooseDialog = () => {
        this.setState({
            showDialogChoose: true,
        });
    };

    closeChooseDialog = () => {
        this.setState({
            showDialogChoose: false,
        });
    };

    handleUpload = (file: any) => {
        this.props.parseImport(file);
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    acknowledgeImport = (file: any) => {
        this.props.acknowledgeImport(file);
        this.closeChooseDialog();
        this.props.clearDiff();
    };

    hideDialogChoose = () => {
        this.props.clearDiff();
        this.setState({
            showDialogChoose: false,
        });
    };

    mapToDiffRow = (list: List<TProject>) => {
        return list.map((project) => (
            <ProjectDiffRow project={project} key={project.externalId} />
        ));
    };

    projectSorter = () => {
        return {
            omschrijving: (a, b) => {
                a = a.omschrijving.toLowerCase();
                b = b.omschrijving.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            totalWPIs: (a, b) => {
                a = this.state.inspectionsAssignsByProject[a.id].length;
                b = this.state.inspectionsAssignsByProject[b.id].length;
                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };


    onItemClicked = (clickedProject) => {
        console.log('INFO: ProjectOverview: onItemClicked: page: ' + this.state.page + ' rowsPerPage: ' + this.state.rowsPerPage );

        let target = 'projecten/details/' + clickedProject.id + '?page=' + this.state.page + '&rowsPerPage=' + this.state.rowsPerPage;
        // Set Project Crumb
        this.props.pushCrumbPath({crumb: { name: i18n.t('Projects'), link: `/projecten?page=${this.state.page}&rows=${this.state.rowsPerPage}`}});
        // Set Project Detail Crumb
        // this.props.pushCrumbPath({crumb: { name: clickedProject.externalId, link: `/projecten/details/${clickedProject.id}`}});

        this.props.goTo(target);
    };

    render() {
        const { loading, projects, t } = this.props;

        const activeProjects = projects.filter((project) => project.active);
        const projectList = projects.filter((project) => project.active);

        const searchresults = activeProjects.filter((row) => {
            let searchtext = this.state.searchtext;
            if (this.state.searchtext === undefined || this.state.searchtext === null || this.state.searchtext.length === 0) {
                searchtext = '';
            }
            return (
                row.omschrijving
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1 ||
                row.opdrachtgever
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1 ||
                row.externalId
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1
            );
        });

        if (
            activeProjects.length !== 0 &&
            this.props.inspectionAssigns.length !== 0 &&
            !this.state.inspectionsAssignsByProject[
                '999InvullenTijdenInspectie'
            ]
        ) {
            this.myList = this.createInspectionByProjectList(activeProjects);
        }

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search within projects / areas of work')}
                onSearchChange={this.handleSearch}
                title={
                    (activeProjects.length - 1) +
                    ' ' +
                    t('project') +
                    (activeProjects.length === 1 ? '' : t('project and')) +
                    '/' +
                    t('workfield') +
                    (activeProjects.length === 1 ? '' : t('project and')) +
                    (this.state.searchtext.length > 0
                        ? (searchresults.length === 1
                              ? ' - ' + searchresults.length + t('searchresult')
                              : '') +
                          (searchresults.length > 1 ||
                          searchresults.length === 0
                              ? ' - ' +
                                searchresults.length +
                                t('searchresults')
                              : '')
                        : '')
                }
            />
        );

        return (
            <div>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <ProjectImportDialog
                        open={this.state.showDialogChoose}
                        handleClose={this.hideDialogChoose}
                        onUpload={this.handleUpload}
                        diff={this.props.diff}
                        acknowledgeImport={this.acknowledgeImport}
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.mapToDiffRow}
                        editProjectFunction={this.props.editProject}
                        editproject={this.props.editproject}
                        onSave={this.saveAndClose}
                        projects={this.props.projects}
                    />

                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <Fab
                            variant="circular"
                            onClick={this.openChooseDialog}
                        >
                            <AddIcon />
                        </Fab>
                    </div>

                    <ProjectsListOverview
                        onItemClick={this.onItemClicked}
                        getLastSentDate={this.getLastSentDate}
                        countFinishedInspectionsPerProject={
                            this.countFinishedInspectionsPerProject
                        }
                        inspectionsAssignsByProject={ this.myList}
                        projects={projectList}
                        searchtext={this.state ? this.state.searchtext : ''}

                        setPage={this.setPage}
                        setRowsPerPage={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}


                    />
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ProjectOverview);
