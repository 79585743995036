// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {IconButton, Input, InputAdornment} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import './ListBlockItem.css';
import classes from './BulletListItem.module.scss';

type Props = {
    classes: any,
    item: any,
    onChange: Function,
    onDelete: Function,
    connectDragPreview: Function,
    connectDragSource: Function,
};

type State = {};


class BulletListItem extends React.Component {
    props: Props;
    state: State = {
        value: this.props.item.text,
    };
    questionType = 'check'; // Default questionType is check item

    timer = null;

    componentDidMount() {
        if (this.props.item.openQuestion === true) {
            this.questionType = 'open';
        } else if (this.props.item.photoQuestion === true) {
            this.questionType = 'photo';
        } else if (this.props.item.yesnoQuestion === true) {
            this.questionType = 'yesno';
        } else {
            this.questionType = 'check';

        }
    }

    handleFormChange = (event) => {
        event.persist();
        const value = event.target.value;

        this.setState({ value });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) this.props.onChange(event);
        }, 400);
    };


    getInputId(path) {
        return this.props.item.id + '-listBlockitem';
    }

    render() {
        const {
            item,
            onDelete,
            connectDragPreview,
            connectDragSource,
            t,
        } = this.props;

        return (
            <div className={classes.root}>
                {connectDragPreview(
                    <div className={classes.wrapper}>
                        {connectDragSource(
                            <div className={classes.dragIcon}>
                                <DragHandleIcon className={classes.icon} />
                            </div>
                        )}
                        <Input
                            fullWidth={true}
                            id={'input-' + item.id}
                            margin="normal"
                            multiline
                            rowsMax="4"
                            onChange={this.handleFormChange}
                            label={t('List part text')}
                            value={this.state.value}
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    style={{ fontSize: '14px', opacity: '0.2' }}
                                >
                                    {500 - item.text.length}
                                </InputAdornment>
                            }
                        />

                        <div style={{ display: 'block'}}>
                        </div>

                        <IconButton
                            className={classes.deleteButton}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </IconButton>

                    </div>
                )}
            </div>
        );
    }
}

export default compose(withTranslation())(BulletListItem);
