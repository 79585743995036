// @flow
import React from 'react'; // eslint-disable-line no-unused-vars


import './TitleWeatherIcon.css';
import sunny from '../../images/weather/cat_zon.png';
import rain from '../../images/weather/cat_bewolkt.png';
import snow from '../../images/weather/cat_sneeuw.png';
import thunderStorm from '../../images/weather/cat_onweer.png';
import freezingRain from '../../images/weather/cat_sneeuwvlok.png';
import storm from '../../images/weather/cat_wind.png';

type Props = {
    toolboxAssignRow?: any,
    loading: boolean
};

type State = {};

class TitleWeatherIcon extends React.Component<Props, State> {
    props: Props;
    state: State = {};


    componentDidMount() {}

    getIcon = itemIconName => {
        switch (itemIconName) {
            case 'sunny':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={sunny} alt={'-'} />
                        </div>
                    </div>
                );
            case 'rain':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={rain} alt={'-'} />
                        </div>
                    </div>
                );
            case 'snow':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={snow} alt={'-'} />
                        </div>
                    </div>
                );
            case 'freezingRain':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={freezingRain} alt={'-'} />
                        </div>
                    </div>
                );
            case 'storm':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={storm} alt={'-'} />
                        </div>
                    </div>
                );
            case 'thunderstorm':
                return (
                    <div className="iconFloat">
                        <div className="iconCenter">
                            <img className="icon" src={thunderStorm} alt={'-'} />
                        </div>
                    </div>
                );
            default:
                return <div>-</div>;
        }
    };

    render() {
        return (
            <div className="childrenContainer">
                <div className="itemTitle">{this.props.itemTitle}</div>
                {this.getIcon(this.props.itemIconName)}
            </div>
        );
    }
}

export default (TitleWeatherIcon);
