// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Viewer from 'react-viewer';
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';
import { percentColors2 } from '../../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './InspectionItem.module.scss';

type Props = {
    item: any,
};

type State = {
    hasTriggered: boolean,
};


class InspectionItem extends React.Component<Props, State> {
    props: Props;
    state: State = {
        imageVisible: false,
        activePhotoId: undefined,
        expanded: false,
        hasTriggered: false,
    };

    componentDidMount() {}

    resultToText = (result, remark, isOpenQuestion, isYesNoQuestion) => {
        const { t } = this.props;
        if (remark && remark.unsafe) {
            return (
                <div style={{ position: 'relative', top: '2px' }}>
                    {t('Unsafe situation')}
                </div>
            );
        }
        if (isOpenQuestion) {
            return '';
        }

        switch (result) {
            case 'ok':
                return isYesNoQuestion ?  t('Yes') : t('Correct');
            case 'nok':
                return isYesNoQuestion ?  t('No') : t('Not correct');
            default:
        }
        return t('Does not apply');
    };

    handleExpandClick = (itemId) => {
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        const { item, t } = this.props;

        const isOpenQuestion = item.openQuestion;
        const isYesNoQuestion = item.yesnoQuestion;
        const hasRemark = item.result && item.result.remark && item.result.remark.text && (item.result.remark.text !== '' && item.result.remark.text !== null);
        const hasPhotos = item.result && item.result.remark && item.result.remark && item.result && item.result.remark && item.result.remark.photos && item.result.remark.photos.length > 0;
        const unsafe = item.result && item.result.remark && item.result.remark && item.result.remark.unsafe;

        if ((hasRemark || hasPhotos || isOpenQuestion) && !this.state.hasTriggered) {
            this.setState({
                hasTriggered: true,
                expanded: true,
            });
        }

        const backgroundColor = this.state.expanded ? '#E8E8E8' : '';

        return (
            <TableBody>
                <TableRow
                    key={item.id}
                    style={{ height: '48px', backgroundColor }}
                >
                    <TableCell>
                        {item.openQuestion && t('Open Question') + ': '}
                        <strong>{item.title}</strong>
                    </TableCell>
                    <TableCell style={{ paddingRight: '0px' }}>
                        <div
                            className={'border-text'}
                            style={{
                                backgroundColor: getColorForPercentage(isOpenQuestion,
                                    item.result === undefined ? 2
                                        : this.resultToText(item.result.result) === 'N.v.t' ? 2
                                        : this.resultToText(item.result.result) === t('Not correct') ? 0
                                        : 1
                                ),
                                borderColor: getColorForPercentage(isOpenQuestion,
                                    item.result === undefined ? 2 :
                                        this.resultToText(item.result.result) === 'N.v.t' ? 2 : this.resultToText(item.result.result) === t('Not correct') ? 0 : 1
                                ),
                                width:
                                    item.result === undefined
                                        ? '70px'
                                        : item.result.remark.unsafe === true
                                        ? '130px'
                                        : '70px',
                                height: '23px',
                                display: 'inline-block',
                                fontWeight: '300',
                            }}
                        >
                            {unsafe && (
                                <WarningIcon
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                        position: 'relative',
                                        top: '4px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        float: 'left',
                                    }}
                                />
                            )}
                            {item.result
                                ? this.resultToText(
                                      item.result.result,
                                      item.result.remark,
                                      isOpenQuestion,
                                      isYesNoQuestion
                                  )
                                : '-'}
                        </div>
                    </TableCell>
                    <TableCell style={{ padding: '20px' }}>
                        <div
                            //className={'border-text'}
                            style={{
                                /*backgroundColor: getColorForPercentage(hasRemark ? 0 : 1),
                                borderColor: getColorForPercentage(hasRemark ? 0 : 1),
                                width: '60px',
                                display: 'inline-block'*/
                                paddingRight: '20px',
                            }}
                        >
                            {isOpenQuestion ? '' : (hasRemark || hasPhotos) ? t('Yes') : '-'}
                        </div>
                    </TableCell>
                    <TableCell style={{ width: '40px', padding: '0' }}>
                        <IconButton
                            className={classnames(
                                classes.expand,
                                'headerButton',
                                {
                                    [classes.expandOpen]: this.state.expanded,
                                }
                            )}
                            onClick={() => this.handleExpandClick(item.id)}
                            aria-expanded={this.state.expanded}
                            style={{ width: '40px' }}
                            aria-label="Show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow key={item.id + '.1'} style={{ height: '0' }}>
                    <TableCell colSpan={4} style={{ padding: '0' }}>
                        <Collapse
                            in={this.state.expanded}
                            timeout="auto"
                            style={{ width: '100%', padding: '0 20px' }}
                        >
                            <Table>
                                {!isOpenQuestion &&
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                style={{ paddingLeft: '50px' }}
                                            >
                                                <div className={classes.h4}>
                                                    {t('Comment')}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                <div className={classes.h4}>
                                                    {t('Attachments')}{' '}
                                                    {hasPhotos && (
                                                        <span>
                                                        {'(' +
                                                            item.result.remark
                                                                .photos.length +
                                                            ')'}
                                                    </span>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                }

                                { isOpenQuestion
                                    ?
                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell

                                                style={{
                                                    paddingLeft: '50px',
                                                    verticalAlign: 'top',
                                                    whiteSpace: 'pre-wrap',
                                                    wordWrap: 'break-word',

                                                }}
                                            >
                                                {item.result &&
                                                item.result.openAnswer
                                                    ? <span className={classes.inspectionOpenAnswer}>{item.result.openAnswer}</span>
                                                    : item.result && item.result.remark && item.result.remark.text
                                                        ? <span className={classes.inspectionOpenAnswer}>{item.result.remark.text}</span>
                                                        : '-'}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>

                                    :

                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell

                                                style={{
                                                    paddingLeft: '50px',
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                {item.result &&
                                                item.result.remark &&
                                                item.result.remark.text
                                                    ? <span className={classes.inspectionComment}>{item.result.remark.text}</span>
                                                    : '-'}
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    className="image-container"
                                                    style={{
                                                        marginTop: '-8px',
                                                        marginLeft: '-8px',
                                                    }}
                                                >
                                                    {hasPhotos &&
                                                        item.result.remark.photos.map(
                                                            (photo) => (
                                                                <div>
                                                                    <img
                                                                        className={
                                                                            classes.preview
                                                                        }
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    imageVisible: true,
                                                                                    activePhotoId:
                                                                                    photo.id,
                                                                                }
                                                                            );
                                                                        }}
                                                                        src={
                                                                            photo.url
                                                                        }
                                                                        alt={t(
                                                                            'Image'
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        )}

                                                    {hasPhotos && (
                                                        <Viewer
                                                            visible={
                                                                this.state
                                                                    .imageVisible
                                                            }
                                                            activeIndex={item.result.remark.photos.findIndex(
                                                                (p) =>
                                                                    p.id ===
                                                                    this.state
                                                                        .activePhotoId
                                                            )}
                                                            drag={false}
                                                            scalable={false}
                                                            zIndex={1505}
                                                            downloadable={true}
                                                            onClose={() => {
                                                                this.setState({
                                                                    imageVisible: false,
                                                                });
                                                            }}
                                                            onMaskClick={() => {
                                                                this.setState({
                                                                    imageVisible: false,
                                                                });
                                                            }}
                                                            images={item.result.remark.photos.map(
                                                                (photo) => {
                                                                    return {
                                                                        src:
                                                                        photo.url,
                                                                        downloadUrl:
                                                                            photo.url +
                                                                            '.jpg',
                                                                    };
                                                                }
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }




                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }
}

var percentColors = percentColors2;

var getColorForPercentage = function (isOpenQuestion, pct) {
    if (isOpenQuestion) {
        return 'rgba(255,255,255,0)';
    }

    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    if (pct === 2) {
        color = percentColors[1];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(withTranslation())(InspectionItem);
