// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {Fab} from '@mui/material';
import InspectionAssignInformation from '../../InspectionAssignInformation';
import InspectionResultsView from '../InspectionResultsView';
import InspectionTrendView from '../InspectionTrendView';
import TaskView from '../../Task/TaskView';
import {
    initInspectionDraft,
    showSnackbar,
    fetchDetailInspectionAssign,
    pushCrumbPath,
    setCrumbPath
} from '../../../actions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { fetchCSV, fetchPDF, fetchInspectionDetail } from '../../../lib/api';
import classnames from 'classnames';
import DescIcon from '@mui/icons-material/Description';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {withRouter} from "react-router";
import classes from './InspectionDetailView.module.scss';
import colors from "../../../lib/themes/theme.scss";
import moment from "../../../lib/moment";


type Props = {
    match: any,
    toolbox?: any,
    fetchEmployee: () => mixed,
    fetchSectors: () => mixed,
    fetchCategories: () => mixed,
    fetchInspections: () => mixed,
    fetchInspectionAssigns: () => mixed,
};

type State = {
    tab: number,
    inspectionAssign: any,
};

const mapStateToProps = (store, props) => {
    const inspectionAssigns = store.entities.inspectionAssigns;
    const allEmployees = store.entities.employees;

    return {
        inspectionAssigns: inspectionAssigns,
        allEmployees: allEmployees,
        allSectors: store.entities.sectors,
        allCategories: store.entities.categories,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initDraft: (inspection) => {
            dispatch(initInspectionDraft(inspection));
        },
        getDetailInspectionAssign: (id) => {
            dispatch(fetchDetailInspectionAssign(id));
        },
        showSnackbar: (message: string) => dispatch(
            showSnackbar(message)
        ),
        setCrumbPath: (crumbs) => {
            dispatch(setCrumbPath(crumbs));
        },
        pushCrumbPath: (name, id) => {
            const search = ownProps.location.search;
            let page = new URLSearchParams(search).get("page");
            if(page == null){
                page = 0;
            }

            let rowsPerPage = new URLSearchParams(search).get("rowsPerPage");
            if( rowsPerPage == null){
                rowsPerPage = 10
            }

            dispatch( pushCrumbPath({ crumb: { name: name, link: '/werkplekinspectie/details/'+ id + '?page='+page+'&rowsPerPage='+rowsPerPage }}) );
        }
    };
};

class InspectionDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
        inspectionAssign: null,
    };

    componentDidMount() {
        console.log("INFO: componentDidMount try to set page and rowsPerPage");
        if (this.props.location.search.includes("page")) {
            const fromPage = new URLSearchParams(this.props.location.search).get("page");

            this.setState({ page: Number(fromPage) });
        }
        if (this.props.location.search.includes("rowsPerPage")) {
            const rowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
            this.setState({ rowsPerPage: Number(rowsPerPage) });
        }

        // Fetch detail inspection assign
        const inspectionAssignId = this.props.match.params.inspectionId;
        fetchInspectionDetail(inspectionAssignId)
            .then((entities) => {
                this.setState({
                    inspectionAssign: entities.body._embedded.inspectionAssign
                });
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching fetchDetailInspectionAssign'
                    )}: ` + error.message
                )
                throw error;
            });
        // Activate the tab based on the #hash
        let tab = 1
        if (this.props.location) {
            if (this.props.location.hash === '#informatie') {
                tab = 0;
            }
            if (this.props.location.hash === '#resultaten') {
                tab = 1;
            }
            if (this.props.location.hash === '#trend') {
                tab = 2;
            }
            if (this.props.location.hash === '#tasks') {
                tab = 3;
            }
        }
        this.setState({
            tab: tab,
        });

        if (this.props.history && this.props.history.action === 'POP') {
            // Page was refreshed / Reloaded
            this.props.setCrumbPath({ title: i18n.t('workplace inspections overview'), crumbs: [ { name: i18n.t('Sent out'), link: '/werkplekinspecties#uitgestuurd' } ] });
        }

    }


    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    // componentDidMount() {
    //     //let tabs;
    //     // Inspection will be undefined after the speed improvements update, keeping this as future reference
    //     // this.props.inspection !== undefined ? tabs = ['#voorbeeld', '#informatie', '#resultaten'] : tabs = ['#informatie', '#resultaten'];
    //     //tabs = ['#informatie', '#resultaten', '#trend'];
    //     // Set default tab to #resultaten
    //
    //     console.log("INFO: componentDidMount try to set page and rowsPerPage");
    //     if (this.props.location.search.includes("page")) {
    //         const fromPage = new URLSearchParams(this.props.location.search).get("page");
    //
    //         this.setState({ page: Number(fromPage) });
    //     }
    //     if (this.props.location.search.includes("rowsPerPage")) {
    //         const rowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
    //         this.setState({ rowsPerPage: Number(rowsPerPage) });
    //     }
    //
    //
    // }

    handlePDFClick = () => {
        const { inspectionAssign } = this.state;
        const {  allEmployees } = this.props;
        const { result } = inspectionAssign;
        const title =
            this.props.inspection == null
                ? inspectionAssign.inspection.title
                : this.props.inspection.title;
        const version =
            this.props.inspection == null
                ? inspectionAssign.inspection.version
                : this.props.inspection.version;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTION',
            options: {},
            data: inspectionAssign.inspection.inspection.categories,
            info: {
                ...inspectionAssign,
                resultEmployees:
                    result != null && result.employees != null
                        ? result.employees.map((id) => {
                              return allEmployees.byId[id];
                          })
                        : [],
                showResults: true,
                address: this.state.address ? this.state.address : '-',
                kamComment: inspectionAssign.kamComment,
                kamResolved: inspectionAssign.kamResolved,
                inspectionAssign,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', title + ' v' + version + '.pdf');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    handleCSVClick = () => {
        const { inspectionAssign } = this.state;
        const { t } = this.props;
        const title =
            this.props.inspection == null
                ? inspectionAssign.inspection.title
                : this.props.inspection.title;
        const version =
            this.props.inspection == null
                ? inspectionAssign.inspection.version
                : this.props.inspection.version;

        let items = [];

        inspectionAssign.inspection.inspection.categories.forEach((c) => {
            c.items.forEach((i) => {
                i.resultaat = i.result.remark.unsafe
                    ? t('Unsafe situation')
                    : i.result.result === 'ok'
                    ? i.yesnoQuestion ? t('Yes') : t('Correct')
                    : i.result.result === 'nok'
                    ? i.yesnoQuestion ? t('No') : t('Not correct')
                    : t('Does not apply');
                items.push(i);
            });
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTIONPREVIEW',
            options: {},
            data: items,
            info: {},
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', title + ' v' + version + '.csv');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    handlePDFClickNoResults = () => {
        const { inspectionAssign, allEmployees } = this.props;
        const { result } = inspectionAssign;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTION',
            options: {},
            data: inspectionAssign.inspection.inspection.categories,
            info: {
                ...inspectionAssign,
                resultEmployees:
                    result != null && result.employees != null
                        ? result.employees.map((id) => {
                              return allEmployees.byId[id];
                          })
                        : [],
                showResults: false,
                inspectionAssign,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    inspectionAssign.inspection.title +
                        ' v' +
                        inspectionAssign.inspection.version +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    geocodeAddress = (address) => {
        var geocoder = new window.google.maps.Geocoder();

        const self = this;
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK') {
                var address = results[0].formatted_address;
                var formatted = results[0].formatted_address.split(', ');
                if (formatted.length === 3) {
                    address = formatted[1] + ', ' + formatted[2];
                }
                self.setState({ address: address });
            }
        });
    };

    render() {
        const {
            inspection,
            inspectionAssigns,
            allEmployees,
            t,
        } = this.props;
        const { tab, inspectionAssign } = this.state;
        const userName = (inspectionAssign && inspectionAssign.user) ? inspectionAssign.user.fullname : "-";
        const inspectionTitle = inspectionAssign ? inspectionAssign.inspection.title : "-"
        const date = (inspectionAssign && inspectionAssign.result) ? moment(inspectionAssign.result.date).format(t('dateTime')) : "-";
        let title = inspectionTitle;
        if (tab === 1) {
            title = date + " - " + userName + " - " + inspectionTitle;
        }

        const tableActionHeader = <TableActionHeader title={title} />;

        if (
            this.state.address == null &&
            inspectionAssign &&
            inspectionAssign.result &&
            inspectionAssign.result.gpsLocation &&
            inspectionAssign.result.gpsLocation.lat &&
            inspectionAssign.result.gpsLocation.lon
        ) {
            this.geocodeAddress(
                inspectionAssign.result.gpsLocation.lat +
                    ',' +
                    inspectionAssign.result.gpsLocation.lon
            );
        }

        if (!inspectionAssign) {
            return t('Loading');
        }
        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{color: colors.mainTabsColor, '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('Information')} href="#informatie" />
                    {inspectionAssign && inspectionAssign.deployed && (
                        <Tab label={t('Results')} href="#resultaten" />
                    )}
                    <Tab label={t('Trend')} href="#trend" />
                    <Tab label={t('Task Title')} href="#tasks" />
                </Tabs>
                {inspectionAssign.inspection !== undefined ? (
                    <AppModule hasTabs prepend={tableActionHeader}>
                        {tab === 0 && inspectionAssigns && (
                            <InspectionAssignInformation
                                inspectionAssign={inspectionAssign}
                                allEmployees={allEmployees}
                            />
                        )}
                        {tab === 1 &&
                            inspectionAssign &&
                            inspectionAssign.deployed && (
                                <InspectionResultsView
                                    inspection={inspection}
                                    inspectionAssign={inspectionAssign}
                                />
                            )}

                        {tab === 1 && (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '84px',
                                    right: '36px',
                                    zIndex: 1500,
                                }}
                            >
                                <div>
                                    <Fab
                                        onClick={this.toggleExport}
                                    >
                                        {this.state && this.state.exportOpen ? (
                                            <CloseIcon />
                                        ) : (
                                            <DownloadIcon />
                                        )}
                                    </Fab>
                                </div>
                                <div
                                    style={
                                        this.state && this.state.exportOpen
                                            ? {
                                                  marginTop: '20px',
                                                  marginLeft: '8px',
                                                  visibility: 'visible',
                                                  transition:
                                                      'margin-top 300ms',
                                              }
                                            : {
                                                  marginTop: '0px',
                                                  marginLeft: '8px',
                                                  visibility: 'hidden',
                                                  transition:
                                                      'margin-top 300ms',
                                              }
                                    }
                                    className={'tooltip'}
                                >
                                    <span
                                        className={
                                            this.state && this.state.exportOpen
                                                ? 'tooltiptextvisible'
                                                : 'tooltiptext'
                                        }
                                        style={{ top: '87px', right: '60px' }}
                                    >
                                        {t('Download PDF file')}
                                    </span>
                                    <Fab
                                        color="primary"
                                        
                                        onClick={this.handlePDFClick}
                                    >
                                        <InsertIcon />
                                    </Fab>
                                </div>
                                <div
                                    style={
                                        this.state && this.state.exportOpen
                                            ? {
                                                  marginTop: '15px',
                                                  marginLeft: '8px',
                                                  visibility: 'visible',
                                                  transition:
                                                      'margin-top 300ms',
                                              }
                                            : {
                                                  marginTop: '0px',
                                                  marginLeft: '8px',
                                                  visibility: 'hidden',
                                                  transition:
                                                      'margin-top 300ms',
                                              }
                                    }
                                    className={'tooltip'}
                                >
                                    <span
                                        className={
                                            this.state && this.state.exportOpen
                                                ? 'tooltiptextvisible'
                                                : 'tooltiptext'
                                        }
                                        style={{ top: '145px', right: '60px' }}
                                    >
                                        {t('Download CSV file')}
                                    </span>
                                    <Fab
                                        color="primary"
                                        
                                        onClick={this.handleCSVClick}
                                    >
                                        <DescIcon />
                                    </Fab>
                                </div>
                            </div>
                        )}
                        {tab === 2 && inspectionAssigns && (
                            <InspectionTrendView
                                inspectionId={this.state.inspectionAssign.inspection.id}
                                allEmployees={allEmployees}
                            />
                        )}
                        {tab === 3 && inspectionAssigns && (
                            <TaskView
                                inspectionAssignId={this.state.inspectionAssign.id}
                            />
                        )}

                    </AppModule>
                ) : (
                    <AppModule hasTabs prepend={tableActionHeader}>
                        {tab === 0 && inspectionAssigns && (
                            <InspectionAssignInformation
                                inspectionAssign={inspectionAssign}
                                allEmployees={allEmployees}
                            />
                        )}
                        {tab === 1 &&
                            inspectionAssign &&
                            inspectionAssign.deployed && (
                                <InspectionResultsView
                                    inspection={inspection}
                                    inspectionAssign={inspectionAssign}
                                />
                            )}

                        {tab === 1 && (
                            <div
                                className={classnames({
                                    [classes.button]: true,
                                    'mui-fixed': true,
                                })}
                            >
                                <div className={'tooltip'}>
                                    <span
                                        className={'tooltiptext'}
                                        style={{ top: '60px' }}
                                    >
                                        {t('Download inspection as PDF')}
                                    </span>
                                    <Fab
                                         
                                        onClick={this.handlePDFClick}
                                    >
                                        <DescIcon />
                                    </Fab>
                                </div>
                            </div>
                        )}
                    </AppModule>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(InspectionDetailView))
);
