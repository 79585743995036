// @flow
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Input,
    FormControl,
    InputLabel,
    Switch,
    Stepper,
    Step,
    StepLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import {
    getAppInspections,
    fetchEmployees,
    sendInspectionResult,
    getAppToolboxes,
    fetchProjects,
    editProject,
} from '../../../actions';


import { getPreviousInspectionResult } from '../../../lib/api'
import _ from 'lodash';

import classes from './InspectionOpdracht.module.scss';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormControlLabel } from '@mui/material';
import { push } from 'connected-react-router';
import TitleDescription from '../../TitleDescription';
import TableActionHeader from '../../TableActionHeader';
import { inProgress } from '../../../selectors';
import LegacyThemeProvider from '../../LegacyThemeProvider';
import SimpleImageInput from '../../SimpleImageInput';
import moment from 'moment';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutoCompleteView from '../../AutoCompleteView';
import ProjectEdit from '../../Project/ProjectEdit';
import { bindActionCreators } from 'redux';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { parseClassesForAutocompleteView } from '../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from "../../../i18n";

type Props = {
    toolboxId: string,
    inspection: Array<any>,
    editproject: any,
};

type State = {
    feedbackText: string,
    results: any,
    unsafe: boolean,
    weatherType: string,
    employees: any,
    location: any,
    photos: any,
    collapsed: any,
    project: any,
    showAddProjectDialog: boolean,
    dialogOpen: boolean,
    hint: string,
    finished: boolean,
    opdrachtgever: string,
    employeeWarningShown: boolean,
};


const mapStateToProps = (store, props) => {
    const inspectionAssignId = props.match.params.toolboxId;

    let inspectionAssign = store.entities.appInspections && store.entities.appInspections.byId[inspectionAssignId];


    return {
        loading: !inProgress(store),
        inspection: inspectionAssign,
        inspectionAssign: inspectionAssign,
        employees: store.entities.employees,
        projects: store.entities.projects,
        editproject: store.drafts.projects.editproject,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendResults: (result) => {
            dispatch(
                sendInspectionResult(ownProps.match.params.toolboxId, result)
            );
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppInspections: () => {
            dispatch(getAppInspections());
        },
        getAppToolboxes: () => {
            dispatch(getAppToolboxes());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        editProject: bindActionCreators(editProject, dispatch),
    };
};

class InspectionOpdracht extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        feedbackText: '',
        results: [],
        unsafe: false,
        weatherType: '',
        employees: [],
        location: { lat: 0.0, lon: 0.0 },
        photos: [],
        collapsed: [],
        project: '',
        showAddProjectDialog: false,
        dialogOpen: false,
        hint: '',
        finished: false,
        employeeWarningShown: false,
    };

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(this.getLocation);
        this.props.getAppInspections();
        this.props.fetchEmployees();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.inspection !== this.state.inspection) {
            this.getPreviousInspection(this.state.inspection.inspection.projectNumber)
        }
        if (this.props.inspection && this.state.inspection === undefined) {
            this.setState({ inspection: this.props.inspection, });
        }
        if (this.props.inspectionAssign && this.state.inspectionAssign === undefined) {
            this.setState({ inspectionAssign: this.props.inspection,});
        }
    }
        

    handleFormChange = (key) => (event) => {
        if (event.target.value.length < 255) {
            this.setState({
                feedbackText: event.target.value,
            });
        }
    };

    handleChange = (event) => {
        if (event.target.id === 'activity') {
            this.setState({
                inspectionAssign: {
                    ...this.state.inspectionAssign,
                    inspection: {
                        ...this.state.inspectionAssign.inspection,
                        activity: event.target.value,
                    },
                },
            });
        } else if (event.target.id === 'externalemployees') {
            this.setState({
                inspectionAssign: {
                    ...this.state.inspectionAssign,
                    inspection: {
                        ...this.state.inspectionAssign.inspection,
                        results: {
                            ...this.state.inspectionAssign.result,
                            externalEmployees: event.target.value,
                        },
                    },
                },
            });
        } else if (event.target.id === 'unsafe') {
            this.setState({
                inspectionAssign: {
                    ...this.state.inspectionAssign,
                    inspection: {
                        ...this.state.inspectionAssign.inspection,
                        results: {
                            ...this.state.inspectionAssign.result,
                            remark: {
                                ...this.state.inspectionAssign.result.remark,
                                unsafe: event.target.value,
                            },
                        },
                    },
                },
            });
        } else if (event.target.id === 'description') {
            this.setState({
                inspectionAssign: {
                    ...this.state.inspectionAssign,
                    description: event.target.value,
                },
            });
        }
    };

    handleChangeProject = (event) => {
        this.setState({
            project: event,
            opdrachtgever: this.props.projects.byId[event.value].opdrachtgever,
            projectId: event.value,
            projectNumber: this.props.projects.byId[event.value].externalId,
        });
        this.getPreviousInspection(this.props.projects.byId[event.value].externalId)
    };

    handleUnsafeChange = (event) => {
        this.setState({
            unsafe: !this.state.unsafe,
        });
    };

    handleImageUpload = (event) => {
        if (event.id !== undefined) {
            this.setState({
                photos: [
                    ...this.state.photos,
                    { id: event.id, url: event.url, photo: event.id },
                ],
            });
        }
    };

    handleImageQuestion = (selected, event, id) => {
        if (event.id !== undefined) {
            if (this.state.results[selected]) {
                this.setState({
                    results: {
                        ...this.state.results,
                        [selected]: {
                            ...this.state.results[selected],
                            id: id,
                            remark: {
                                ...this.state.results[selected].remark,
                                photos: [
                                    ...this.state.results[selected].remark
                                        .photos,
                                    {
                                        id: event.id,
                                        url: event.url,
                                        photo: event.id,
                                    },
                                ],
                            },
                        },
                    },
                });
            } else {
                this.setState({
                    results: {
                        ...this.state.results,
                        [selected]: {
                            ...this.state.results[selected],
                            id: id,
                            remark: {
                                photos: [
                                    {
                                        id: event.id,
                                        url: event.url,
                                        photo: event.id,
                                    },
                                ],
                                text: null,
                                unsafe: false,
                            },
                            result: null,
                        },
                    },
                });
            }
        }
    };

    handleRemoveImageQuestion = (selected, id) => {
        if (this.state.results[selected]) {
            let photos = this.state.results[selected].remark.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
            });
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            photos: newPhotos,
                        },
                    },
                },
            });
        }
    };

    handleChangeEmployees = (event) => {
        this.setState({
            employees: event.target.value,
        });
    };

    getItemTitleForId = (id) => {
        var title = 'Niet gevonden';
        if (this.state.inspectionAssign && this.state.inspectionAssign.inspection) {
            this.state.inspectionAssign.inspection.categories.forEach((cat) => {
                cat.items.forEach((item) => {
                    if (item.id.trim() === id.trim()) {
                        title = item.title;
                    }
                });
            });
        }
        return title;
    }
    searchMissingQuestion = (answeredQuestions) => {
        var continueSearching = true;
        if (this.state.inspectionAssign && this.state.inspectionAssign.inspection) {
            this.state.inspectionAssign.inspection.categories.forEach((cat) => {
                if (continueSearching) {
                    cat.items.forEach((item) => {
                        var found = false;
                        let id = item.id;
                        if (answeredQuestions.includes(id)) {
                            found = true;
                        }
                        if (found === false) {
                            var title = this.getItemTitleForId(id);
                            var message = i18n.t('Answer missing for question') + title;
                            this.setState({
                                hint: message,
                            });
                            continueSearching = false;
                        }
                    });
                }
            });
        }
    }

    toEndPage = () => {
        const results = this.state.results;
        let questionAmount = 0;
        let questionsCompleted = 0;
        let photoQuestionsComplete = 0;
        let manditoryPhotoQuestions = [];
        var answeredQuestions = [];

        this.state.inspectionAssign.inspection.categories.forEach((cat) => {
            cat.items.forEach((item, index) => {
                if (item.photoQuestion) {
                    manditoryPhotoQuestions.push(item.id);
                } else {
                    questionAmount++;
                }
            });
        });

        // Check photo answers
        for (let i = 0; i < manditoryPhotoQuestions.length; i++) {
            let id = manditoryPhotoQuestions[i];
            for (let key in results) {
                if ( results[key] && results[key].id === id && (results[key].remark.photos.length > 0) ) {
                    photoQuestionsComplete++;
                }
            }
        }
        if (manditoryPhotoQuestions.length !== photoQuestionsComplete) {
            this.setState({
                dialogOpen: true,
            });
            return;
        }

        // Check check-items answers
        for (var key in results) {
            if (results[key] && results[key].result === 'nok') {
                if (
                    results[key].remark.text !== null &&
                    results[key].remark.text.length > 0
                ) {
                    questionsCompleted++;
                    answeredQuestions.push(results[key].id);
                } else {
                    var title = this.getItemTitleForId(results[key].id);
                    var message = i18n.t('Remark missing for question') + title;
                    this.setState({
                        hint: message,
                    });

                }
            } else if (results[key] && results[key].result !== null) {
                questionsCompleted++;
                answeredQuestions.push(results[key].id);
            }
        }

        if (questionsCompleted === questionAmount) {
            this.setState({
                stepIndex: this.state.stepIndex + 1,
            });

            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            // Find the question for the missing result
            this.searchMissingQuestion(answeredQuestions);

            this.setState({
                dialogOpen: true,
            });
        }
    };

    toSelfTest = () => {
        const { t } = this.props;
        if (
            this.state.employees &&
            this.state.employees.length === 0 &&
            !this.state.employeeWarningShown
        ) {
            // Show an dialog telling that there are no employees selected
            this.setState({
                employeeWarningShown: true,
                inspectionErrorDialogOpen: true,
                inspectionErrorDialogText: t('No employees selected, still start the inspection'),
            });
        } else {
            this.setState({
                stepIndex: this.state.stepIndex + 1,
            });

            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    };

    getPreviousInspection = (projectId) => {
        getPreviousInspectionResult(this.state.inspectionAssign.id, projectId)
        .then((res) => {
            let previousInspection = res.body._embedded.previousInspection
            this.setState({
                previousInspection : previousInspection
            })
        }).catch((error) => {
            throw error;
        })
    }

    sendResults = () => {
        this.setState({
            finished: true,
        });

        let resultsArray = [];

        for (var key in this.state.results) {
            resultsArray.push(this.state.results[key]);
        }

        let results = {
            complete: true,
            results: resultsArray,
            externalEmployees:
                this.state.inspectionAssign.inspection.results &&
                this.state.inspectionAssign.inspection.results.externalEmployees
                    ? this.state.inspectionAssign.inspection.results
                          .externalEmployees
                    : '',
            weatherType: this.state.weatherType,
            date: moment(),
            activity: this.state.inspectionAssign.inspection.activity
                ? this.state.inspectionAssign.inspection.activity
                : '',
            projectId:
                this.props.inspectionAssign.project &&
                this.props.inspectionAssign.project.id &&
                this.props.inspectionAssign.project.id !==
                    '999InvullenTijdenInspectie'
                    ? this.props.inspectionAssign.project.id
                    : this.state.project.value,
            remarks: {
                photos: this.state.photos,
                text: this.state.feedbackText,
                unsafe: this.state.unsafe,
            },
            gpsLocation: this.state.location,
            employees: this.state.employees,
            omschrijving: this.state.inspectionAssign.description
                ? this.state.inspectionAssign.description
                : '',
        };

        this.props.sendResults(results);
    };

    backToOverview = () => {
        this.props.goTo('/web/opdrachten');
        this.props.getAppToolboxes();
        this.props.getAppInspections();
        this.props.fetchProjects();
    };

    getLocation = (event) => {
        this.setState({
            location: {
                lat: event.coords.latitude,
                lon: event.coords.longitude,
            },
        });
    };

    handleRemoveImage = (id) => {
        if (this.state.photos) {
            let photos = this.state.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
                return null
            });

            this.setState({
                photos: newPhotos,
            });
        }
    };

    showAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: true,
        });
        this.cleanEditProject(this.props);
    };

    cleanEditProject = (props: Props) => {
        props.editProject({
            omschrijving: '',
            opdrachtgever: '',
            importType: 'MANUAL',
        });
    };

    hideAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: false,
        });
    };

    handleAnswerClick = (selected, result, id) => {
        if (
            this.state.results[selected] &&
            this.state.results[selected].result === 'nok' &&
            this.state.results[selected].remark &&
            this.state.results[selected].remark.text === null
        ) {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: false },
                },
            });
        }
        if (
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        result: result,
                        id: id,
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        result: result,
                        id: id,
                        remark: { photos: [], text: null, unsafe: false },
                    },
                },
            });
        }
        if (result === 'nok') {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: true },
                },
            });
        }
    };

    handleCollapseClick = (selected) => {
        console.log("colapsing", selected);
        if (this.state.collapsed && this.state.collapsed[selected]) {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: {
                        collapsed: !this.state.collapsed[selected].collapsed,
                    },
                },
            });
        } else {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: true },
                },
            });
        }
    };

    handleUnsafeAnswer = (selected, id) => {
        if (
            this.state.results &&
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            unsafe: !this.state.results[selected].remark.unsafe,
                        },
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: { photos: [], text: null, unsafe: true },
                    },
                },
            });
        }
    };

    handleChangeOpenAnswer = (selected, event, id) => {
        this.setState({
            results: {
                ...this.state.results,
                [selected]: {
                    ...this.state.results[selected],
                    openAnswer: event.target.value,
                    id: id,
                    remark: { photos: [], text: null, unsafe: false },
                },
            },
        });
    };


    handleChangeOpmerking = (selected, event, id) => {
        if (
            this.state.results &&
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            text: event.target.value,
                        },
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            photos: [],
                            text: event.target.value,
                            unsafe: false,
                        },
                        result: null,
                    },
                },
            });
        }
    };

    handlePageBack = () => {
        if (this.state.stepIndex === 0) {
            this.backToOverview();
        } else {
            this.setState({
                stepIndex: this.state.stepIndex - 1,
            });
        }
    };

    render() {
        const {
            loading,
            employees,
            unsafe,
            projects,
            editproject,
            t,
        } = this.props;
        const {
            stepIndex,
            inspectionErrorDialogOpen,
            inspectionErrorDialogText,
            inspection,
            inspectionAssign,
            showAddProjectDialog,
            dialogOpen,
            hint,
            previousInspection,
        } = this.state;

        const tableActionHeader = <TableActionHeader />;

        // window.$crisp.push(['do', 'chat:hide']);

        let myCounter = 0;

        if (
            !inspection ||
            (inspection &&
                inspection.inspection.results &&
                inspection.inspection.results.complete === true)
        ) {
            return (
                <AppModule className="appModule" loading={loading}>
                    <div>
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t(
                                    'This workplace inspection is no longer available'
                                )}
                            </p>
                            <br />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.backToOverview}
                                    id="sendresultsButton"
                                    style={{
                                        margin: 'auto',
                                        marginBottom: '100px',
                                    }}
                                >
                                    {t('Back to the overview')}
                                </Button>
                            </div>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/facebook.png"
                                        alt="Veilig Vakwerk"
                                    />
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/twitter.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img
                                        src="/assets/images/linkedin.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </AppModule>
            );
        }

        return (
            <div className="App">
                <Dialog
                    open={dialogOpen}
                    hint={hint}
                    onClose={() => this.setState({ dialogOpen: false })}
                >
                    <DialogTitle className="title">
                        {t('Not all answers have been entered')}
                    </DialogTitle>
                    <DialogContent>
                        <div>{hint}</div>
                        <br/>
                        <div>{t('Check whether all answers have been entered and whether a comment has been entered for incorrect answers')}</div>
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button
                             
                            className="confirmButton"
                            onClick={() => this.setState({ dialogOpen: false })}
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>

                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        {stepIndex !== 3 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon className={classes.backButton}/>
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            type="title"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig vakwerk')}
                        </Typography>
                    </Toolbar>
                    <div className={classes.stepperWrapper}>
                        <div className={classes.stepper} id="stepper">
                            <LegacyThemeProvider>
                                <Stepper activeStep={stepIndex}>
                                    <Step>
                                        <StepLabel>{t('General')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Inspection')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Other')}</StepLabel>
                                    </Step>
                                </Stepper>
                            </LegacyThemeProvider>
                        </div>
                    </div>
                </AppBar>

                <AppModule
                    className="appModule"
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    <ProjectEdit
                        open={showAddProjectDialog}
                        editproject={editproject}
                        backOnCancel={false}
                        onCancel={this.hideAddProjectDialog}
                    />
                    {stepIndex === 0 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h1>{t('General information')}</h1>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Planned date')}
                                itemDescription={moment(
                                    inspection.deployed
                                ).format(t('dateTime'))}
                            />
                            {inspection.inspection.projectNumber && (
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Project/work area')}
                                    itemDescription={
                                        inspection.inspection.projectName
                                    }
                                />
                            )}
                            {inspection.inspection.projectNumber ? (
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Project number')}
                                    itemDescription={
                                        inspection.inspection.projectNumber
                                    }
                                />
                            ) : (
                                <div>
                                    <FormControl
                                        style={{
                                            width: 'calc(100% - 30px)',
                                            display: 'inline-block',
                                        }}
                                    >
                                        <InputLabel style={{ marginTop: '10px'}}>
                                            {t('Project/work area')}
                                        </InputLabel>
                                        <Input
                                            label="Project/work area"
                                            multiline
                                            fullWidth={true}
                                            inputComponent={(props) => (
                                                <AutoCompleteView
                                                    {...props}
                                                    classes={parseClassesForAutocompleteView(props)}
                                                    isToolbox={true}
                                                    filterOption={(project,filterString) =>
                                                        project.label ? project.label.includes(filterString) : false
                                                    }
                                                />
                                            )}
                                            value={this.state.project}
                                            placeholder=" "
                                            onChange={((event) => this.handleChangeProject(event))}
                                            inputProps={{
                                                classes,
                                                instanceId: 'react-select-single',
                                                name: 'react-select-single',
                                                simpleValue: true,
                                                options: projects.allIds
                                                    .filter((id) => !id.includes('InvullenTijdenInspectie'))
                                                    .map((id) => projects.byId[id])
                                                    .sort(function (a, b) {
                                                        const numberA = parseInt(a.externalId, 10);
                                                        const numberB = parseInt(b.externalId,10);

                                                        if ( isNaN(numberA) && !isNaN(numberB) ) {
                                                            return 1;
                                                        } else if ( !isNaN(numberA) && isNaN(numberB)) {
                                                            return -1;
                                                        } else if ( !isNaN(numberA) && !isNaN(numberB)) {
                                                            return (numberA - numberB);
                                                        } else {
                                                            return a.externalId > b.externalId ? 1 : -1;
                                                        }
                                                    })
                                                    .map((project) => ({
                                                        title: project.omschrijving,
                                                        value: project.id,
                                                        label:
                                                            project.externalId +
                                                            ': ' +
                                                            project.omschrijving,
                                                    })),
                                            }}
                                        />
                                    </FormControl>
                                    <IconButton
                                        className={'tooltip'}
                                        aria-label="Add"
                                        onClick={this.showAddProjectDialog}
                                        style={{
                                            verticalAlign: 'top',
                                            marginTop: '28px',
                                            width: '30px',
                                        }}
                                    >
                                        <span
                                            className={'tooltiptext'}
                                            style={{
                                                left: '10px',
                                                top: '-10px',
                                            }}
                                        >
                                            {t('Add project')}
                                        </span>
                                        <AddCircleOutline />
                                    </IconButton>
                                </div>
                            )}
                            {this.props.inspection.description === '' ? (
                                <FormControl className={classes.item}>
                                    <InputLabel htmlFor="description">
                                        {t('Description')}
                                    </InputLabel>
                                    <Input
                                        fullWidth={true}
                                        id="description"
                                        inputProps={{ autoComplete: 'off' }}
                                        
                                        onChange={this.handleChange}
                                        label={t('Description')}
                                        required
                                        value={
                                            inspectionAssign &&
                                            inspectionAssign.description
                                                ? inspectionAssign.description
                                                : ''
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Description')}
                                    itemDescription={inspection.description}
                                />
                            )}

                            <h1 style={{ marginTop: '64px' }}>
                                {t('Customer / activity')}
                            </h1>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Customer')}
                                itemDescription={
                                    inspection.inspection.customer
                                        ? inspection.inspection.customer
                                        : this.state.opdrachtgever
                                        ? this.state.opdrachtgever
                                        : '-'
                                }
                            />
                            <FormControl className={classes.item}>
                                <InputLabel htmlFor="activity">
                                    {t('Work type')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="activity"
                                    inputProps={{ autoComplete: 'off' }}
                                    
                                    onChange={this.handleChange}
                                    label={t('Work type')}
                                    required
                                    value={
                                        inspectionAssign &&
                                        inspectionAssign.inspection
                                            ? inspectionAssign.inspection
                                                  .activity
                                            : ''
                                    }
                                />
                            </FormControl>
                            <h1 style={{ marginTop: '64px' }}>{t('Staff')}</h1>
                            <FormControl>
                                <InputLabel htmlFor="EmployeeInputLabel">
                                    {t('Employees')}
                                </InputLabel>
                                <Select
                                    fullWidth={true}
                                    id="employees"
                                    input={<Input id="employeesInput" />}
                                    
                                    onChange={this.handleChangeEmployees}
                                    placeholder="Medewerkers"
                                    multiple
                                    value={this.state.employees}
                                >
                                    {employees.allIds
                                        .map((id) => employees.byId[id])
                                        .filter(
                                            (empl) => empl.state === 'ACTIVE'
                                        )
                                        .map((employee) => (
                                            <MenuItem
                                                key={employee.id}
                                                value={employee.id}
                                            >
                                                {employee.fullname}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.item}>
                                <InputLabel htmlFor="externalemployees">
                                    {t('Other attendees')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="externalemployees"
                                    inputProps={{ autoComplete: 'off' }}
                                    
                                    onChange={this.handleChange}
                                    label={t('External employees')}
                                    required
                                    value={
                                        inspectionAssign &&
                                        inspectionAssign.inspection.results &&
                                        inspectionAssign.inspection.results
                                            .externalEmployees
                                            ? inspectionAssign.inspection
                                                  .results.externalEmployees
                                            : ''
                                    }
                                />
                            </FormControl>
                            <h1 style={{ marginTop: '64px' }}>{t('Others')}</h1>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Choose weather condition')}
                            />
                            <div
                                style={{ width: '350px', marginBottom: '50px', height: '200px' }}
                            >
                                <div className={classes.weatherlist}>
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'sunny',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'sunny'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_zon_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_zon.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'rain',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'rain'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_bewolkt_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_bewolkt.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'snow',
                                            })
                                        }
                                        style={
                                            this.state.weatherType ===
                                            'snow'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuw_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuw.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                </div>
                                <div className={classes.weatherlist}>
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'storm',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'storm'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_wind_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_wind.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'thunderstorm',
                                            })
                                        }
                                        style={
                                            this.state.weatherType ===
                                            'thunderstorm'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_onweer_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_onweer.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'freezingRain',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'freezingRain'
                                                ? {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuwvlok_activated.png")',
                                                      backgroundColor:
                                                          '#272D35',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuwvlok.png")',
                                                  }
                                        }
                                        className={classes.weatherblock}
                                    />
                                </div>
                            </div>
                            <hr />
                            <Dialog
                                open={inspectionErrorDialogOpen}
                                onClose={() => {
                                    this.setState({
                                        inspectionErrorDialogOpen: false,
                                    });
                                }}
                            >
                                <DialogTitle>{t('Pay attention')}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {inspectionErrorDialogText}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                inspectionErrorDialogOpen: false,
                                            });
                                        }}
                                         
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                inspectionErrorDialogOpen: false,
                                            });
                                            this.toSelfTest();
                                        }}
                                         
                                    >
                                        {t('Ok')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toSelfTest}
                                disabled={
                                    this.state.weatherType === '' ||
                                    (this.props.inspection.inspection
                                        .projectNumber === '' &&
                                        this.state.project === '')
                                }
                            >
                                {t('Start inspection')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 1 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                        {!_.isEmpty(previousInspection) && (
                            <div classes={classes.previousAnwsers}>
                                <h2>{t('Previous Findings')}</h2>
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('These shortcomings were noted in the previous WPI')}
                                />

                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Workplace inspection')}
                                    itemDescription={previousInspection.inspection_title}
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Reported by')}
                                    itemDescription={previousInspection.user}
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Date')}
                                    itemDescription={moment(previousInspection.date).format('L HH:mm')}
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Incorrect')}
                                    itemDescription={previousInspection.incorrect ? t('Yes') : t('No')}
                                />

                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Unsafe')}
                                    itemDescription={previousInspection.wpi_unsafe ? t('Yes') : t('No')}
                                />

                                {previousInspection.wpi_comment &&(
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Workplace inspection comment')}
                                        itemDescription={previousInspection.wpi_comment}
                                    />
                                )}

                                {previousInspection.incorrect && (
                                    <h2 style={{marginTop: '40px'}}>{t('Check the following items')}</h2>
                                )}
                                                
                                {previousInspection.issue_list && previousInspection.issue_list.map((element) => (
                                    <div key={element.item_id}>
                                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} />

                                        <TitleDescription
                                            className={classes.item}
                                            itemTitle={t('Title')}
                                            itemDescription={element.title}
                                        />

                                        <TitleDescription
                                            className={classes.item}
                                            itemTitle={t('Comment')}
                                            itemDescription={element.comment}
                                        />

                                        <TitleDescription
                                            className={classes.item}
                                            itemTitle={t('Unsafe')}
                                            itemDescription={element.unsafe ? t('Yes') : t('No')}
                                        />          

                                    </div>
                                ))}
                                <hr style={{ marginTop: '10px' }} />
                            </div>
                            )}
                            {inspectionAssign.inspection.categories.map(
                                (cat) => (
                                    <div>
                                        <div style={{ marginBottom: '10px', marginTop: '40px',}}>
                                            <h2>{cat.title}</h2>
                                        </div>{' '}
                                        {cat.items.map((item, idx) => {
                                            const index = myCounter += 1
                                            return (


                                            item.openQuestion ? (
                                                    <div style={{ marginTop: '40px' }}>
                                                        <TitleDescription className={classes.item} itemTitle={item.title}/>

                                                        <FormControl className={classes.item}>
                                                            <InputLabel htmlFor="openvraag">
                                                                {t('Open Question')}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth={true}
                                                                multiline={true}
                                                                id="opmerking"
                                                                inputProps={{ autoComplete: 'off', }}

                                                                onChange={ (event) => this.handleChangeOpenAnswer(index,event,item.id)}
                                                                label={t('Open Question')}
                                                            />
                                                        </FormControl>
                                                    </div>

                                                ) : (
                                                    <div style={{ marginTop: '40px' }}>
                                                        <TitleDescription className={classes.item} itemTitle={item.title}/>

                                                            { item.openQuestion !== true && item.photoQuestion !== true &&
                                                                (

                                                                    <div>

                                                            <div
                                                                onClick={() => this.handleAnswerClick(index, 'ok', item.id)}
                                                                style={
                                                                    this.state.results && this.state.results[index] && this.state.results[index].result === 'ok'
                                                                        ? {
                                                                            cursor: 'pointer', height: '32px', width: '87px', border: '0.9px solid #272D35', borderRadius: '8px', textAlign: 'center', lineHeight: '2', float: 'left', backgroundColor:  '#272D35', color:  'white',
                                                                        }
                                                                        : {
                                                                            height:  '32px', width:   '87px', border: '0.9px solid #272D35', borderRadius:  '8px', textAlign:  'center', lineHeight:  '2', float: 'left', cursor: 'pointer',
                                                                        }
                                                                }
                                                                id="checklist"
                                                            >
                                                                { item.yesnoQuestion === true ? t('Yes') : t('Correct')}
                                                            </div>
                                                            <div
                                                                onClick={() => this.handleAnswerClick(index,'nok',item.id)}
                                                                style={
                                                                    this.state.results && this.state.results[index] && this.state.results[index].result === 'nok'
                                                                        ? {
                                                                            cursor: 'pointer', height: '32px', width: '87px', border: '0.9px solid #272D35', borderRadius: '8px', textAlign: 'center', lineHeight: '2', float: 'left', backgroundColor: '#272D35', color: 'white', marginLeft: '10px',
                                                                        }
                                                                        : {
                                                                            height: '32px', width: '87px', border: '0.9px solid #272D35', borderRadius: '8px', textAlign: 'center', lineHeight: '2', float: 'left', marginLeft: '10px', cursor: 'pointer',
                                                                        }
                                                                }
                                                                id="checklist"
                                                            >
                                                                { item.yesnoQuestion === true ? t('No') : t('Not correct')}
                                                            </div>

                                                            { !item.yesnoQuestion && (
                                                            <div
                                                                onClick={() => this.handleAnswerClick(index,'na',item.id)}
                                                                style={
                                                                    this.state.results && this.state.results[index] && this.state.results[index].result === 'na'
                                                                        ? {
                                                                            cursor: 'pointer', height: '32px', width: '87px', border: '0.9px solid #272D35', borderRadius: '8px', textAlign: 'center', lineHeight: '2', float: 'left', backgroundColor: '#272D35', color: 'white', marginLeft: '10px',
                                                                        }
                                                                        : {
                                                                            height: '32px', width: '87px', border: '0.9px solid #272D35', borderRadius: '8px', textAlign: 'center', lineHeight: '2', float: 'left', marginLeft: '10px', cursor: 'pointer',
                                                                        }
                                                                }
                                                                id="checklist"
                                                            >
                                                                {t('NA')}
                                                            </div>
                                                            )}
                                                        </div>

                                                        )}

                                                        <IconButton
                                                            onClick={() => this.handleCollapseClick(index)}
                                                            style={{ float: 'right' }}
                                                            id="expandbutton2"
                                                        >
                                                            {(this.state.collapsed && this.state.collapsed[index] && this.state.collapsed[index].collapsed === true) || item.photoQuestion === true
                                                                ? ( <ChevronUpIcon />) : (<ChevronDownIcon />)}
                                                        </IconButton>
                                                        <br />
                                                        <FormControl
                                                            className={classes.item}
                                                            style={
                                                                (this.state.collapsed &&
                                                                this.state.collapsed[index] &&
                                                                this.state.collapsed[index].collapsed === true) ||
                                                                item.photoQuestion === true
                                                                    ? {}
                                                                    : {
                                                                        visibility: 'hidden',
                                                                        height: '0',
                                                                        width: '0',
                                                                    }
                                                            }
                                                        >
                                                            <InputLabel htmlFor="opmerking">
                                                                {t('comments')}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth={true}
                                                                id="opmerking"
                                                                inputProps={{
                                                                    autoComplete: 'off',
                                                                }}
                                                                onChange={ (event) => this.handleChangeOpmerking(index,event,item.id)}
                                                                label={t('Comment')}
                                                            />
                                                        </FormControl>
                                                        {((this.state.collapsed && this.state.collapsed[index] && this.state.collapsed[index].collapsed === true) || item.photoQuestion === true) && (
                                                            <div
                                                                style={{
                                                                    marginTop: '20px',
                                                                    marginBottom: '20px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{ display: 'inline-block', }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <SimpleImageInput
                                                                            id={index + '-0'}
                                                                            onChange={((event) => this.handleImageQuestion(index,event,item.id))}
                                                                            url={
                                                                                this.state.results &&
                                                                                this.state.results[index] &&
                                                                                this.state.results[index].remark.photos[0]
                                                                                    ? this.state.results[index].remark.photos[0].url
                                                                                    : ''
                                                                            }
                                                                        />
                                                                        {this.state.results && this.state.results[index] &&
                                                                            this.state.results[index].remark.photos[0] && (
                                                                                <IconButton
                                                                                     
                                                                                    className="drawer-button"
                                                                                    onClick={() => this.handleRemoveImageQuestion(index,0)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        bottom: '-5px',
                                                                                        right: '-20px',
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon className="drawer-icon" />
                                                                                </IconButton>
                                                                            )}
                                                                    </div>
                                                                    {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[0] && (
                                                                        <div
                                                                            style={{
                                                                                float: 'left',
                                                                                marginRight: '10px',
                                                                                marginTop: '10px',
                                                                                position: 'relative',
                                                                            }}
                                                                        >
                                                                            <SimpleImageInput
                                                                                id={ index + '-1'}
                                                                                onChange={(event) => this.handleImageQuestion(index,event,item.id)}
                                                                                url={
                                                                                    this.state.results && this.state.results[index] && this.state.results[index].remark.photos[1]
                                                                                        ? this.state.results[index].remark.photos[1].url : ''
                                                                                }
                                                                            />
                                                                            {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[1] && (
                                                                                <IconButton
                                                                                     
                                                                                    className="drawer-button"
                                                                                    onClick={() => this.handleRemoveImageQuestion(index,1)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        bottom: '-5px',
                                                                                        right: '-20px',
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon className="drawer-icon" />
                                                                                </IconButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[1] && (
                                                                        <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                            <SimpleImageInput
                                                                                id={index + '-2'}
                                                                                onChange={(event) => this.handleImageQuestion(index, event, item.id)}
                                                                                url={
                                                                                    this.state.results && this.state.results[index] && this.state.results[index].remark.photos[2]
                                                                                        ? this.state.results[index].remark.photos[2].url
                                                                                        : ''
                                                                                }
                                                                            />
                                                                            {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[2] && (
                                                                                <IconButton
                                                                                     
                                                                                    className="drawer-button"
                                                                                    onClick={() => this.handleRemoveImageQuestion(index,2)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        bottom: '-5px',
                                                                                        right: '-20px',
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon className="drawer-icon" />
                                                                                </IconButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {this.state.results && this.state.results[index] &&this.state.results[index].remark.photos[2] && (
                                                                        <div
                                                                            style={{
                                                                                float: 'left',
                                                                                marginRight: '10px',
                                                                                marginTop: '10px',
                                                                                position: 'relative',
                                                                            }}
                                                                        >

                                                                            <SimpleImageInput
                                                                                id={index + '-3'}
                                                                                onChange={(event) => this.handleImageQuestion(index,event,item.id)}
                                                                                url={
                                                                                    this.state.results && this.state.results[index] && this.state.results[index].remark.photos[3]
                                                                                        ? this.state.results[index].remark.photos[3].url
                                                                                        : ''
                                                                                }
                                                                            />
                                                                            {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[3] && (
                                                                                <IconButton   className="drawer-button"
                                                                                            onClick={() => this.handleRemoveImageQuestion(index,3)}
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-5px',
                                                                                                right: '-20px',
                                                                                            }}
                                                                                >
                                                                                    <DeleteIcon className="drawer-icon" />
                                                                                </IconButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[3] && (
                                                                        <div
                                                                            style={{
                                                                                float: 'left',
                                                                                marginRight: '10px',
                                                                                marginTop: '10px',
                                                                                position: 'relative',
                                                                            }}
                                                                        >
                                                                            <SimpleImageInput id={ index + '-4'}
                                                                                              onChange={(event) => this.handleImageQuestion(index,event,item.id)}
                                                                                              url={this.state.results && this.state.results[index] && this.state.results[index].remark.photos[4]
                                                                                                  ? this.state.results[index].remark.photos[4].url
                                                                                                  : ''
                                                                                              }
                                                                            />
                                                                            {this.state.results && this.state.results[index] && this.state.results[index].remark.photos[4] && (
                                                                                <IconButton   className="drawer-button"
                                                                                            onClick={() => this.handleRemoveImageQuestion(index,4)}
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-5px',
                                                                                                right: '-20px',
                                                                                            }}
                                                                                >
                                                                                    <DeleteIcon className="drawer-icon" />
                                                                                </IconButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <FormControl>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch checked={this.state.results && this.state.results[index] && this.state.results[index].remark && this.state.results[index].unsafe}
                                                                                    onChange={() => this.handleUnsafeAnswer(index,item.id)}
                                                                                    id="unsafe" />
                                                                        }
                                                                        label={t('Unsafe situation')}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                        <hr
                                                            style={{
                                                                marginTop: '10px',
                                                            }}
                                                        />

                                                    </div>
                                                )




                                        )}
                                        )}
                                    </div>
                                )
                            )}
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toEndPage}
                            >
                                {t('Complete inspection')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 2 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h2 style={{ textAlign: 'center' }}>
                                {t('Complete workplace inspection')}
                            </h2>
                            <p>
                                {t(
                                    'Thanks for filling in, complete the workplace inspection here. After you have completed the workplace inspection, you can find the results under Completed assignments in the menu.'
                                )}
                            </p>
                            <br />
                            <p>{t('Enter other comment')}</p>
                            <textarea
                                name="feedbackfield"
                                cols="120"
                                rows="4"
                                value={this.state.feedbackText}
                                onChange={this.handleFormChange('text')}
                                style={{
                                    resize: 'none',
                                    width: '100%',
                                    fontSize: '16px',
                                }}
                            />
                            <br />
                            <div style={{ display: 'inline-block' }}>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                        position: 'relative',
                                    }}
                                >
                                    <SimpleImageInput
                                        id={'feedbackphoto0'}
                                        onChange={this.handleImageUpload}
                                        url={
                                            this.state.photos &&
                                            this.state.photos[0]
                                                ? this.state.photos[0].url
                                                : ''
                                        }
                                    />
                                    {this.state.photos && this.state.photos[0] && (
                                        <IconButton
                                             
                                            className="drawer-button"
                                            onClick={() =>
                                                this.handleRemoveImage(0)
                                            }
                                            style={{
                                                position: 'absolute',
                                                bottom: '-5px',
                                                right: '-20px',
                                            }}
                                        >
                                            <DeleteIcon className="drawer-icon" />
                                        </IconButton>
                                    )}
                                </div>
                                {this.state.photos[0] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto1'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[1]
                                                    ? this.state.photos[1].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[1] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() =>
                                                        this.handleRemoveImage(
                                                            1
                                                        )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                )}
                                {this.state.photos[1] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto2'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[2]
                                                    ? this.state.photos[2].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[2] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() =>
                                                        this.handleRemoveImage(
                                                            2
                                                        )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                )}
                                {this.state.photos[2] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto3'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[3]
                                                    ? this.state.photos[3].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[3] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() =>
                                                        this.handleRemoveImage(
                                                            3
                                                        )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                )}
                                {this.state.photos[3] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto4'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[4]
                                                    ? this.state.photos[4].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[4] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() =>
                                                        this.handleRemoveImage(
                                                            4
                                                        )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                )}
                            </div>
                            <br />
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={unsafe}
                                            onChange={this.handleUnsafeChange}
                                            id="unsafeInspection"
                                        />
                                    }
                                    label={t('Unsafe situation')}
                                />
                            </FormControl>
                            <br />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.sendResults}
                                disabled={this.state.finished}
                                id="sendresultsButton"
                            >
                                {t('Complete inspection')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(InspectionOpdracht);
